<template>
  <div class="flix-text-center">
    <flixLoader />
    <h1 class="flix-html-h4">{{ $t("message.logout") }}</h1>
    <h4 class="flix-html-h5" v-if="counter > 1">{{ counter }} {{ $t("message.seconds") }}</h4>
    <h4 class="flix-html-h5" v-else>{{ counter }} {{ $t("message.second") }}</h4>
  </div>
</template>
<script>
export default {
  data () {
    return {
      counter: 5,
      timer: []
    }
  },
  methods: {
    setCounter () {
      this.timer[1] = setTimeout(function () {
        this.counter = this.counter - 1
        this.setCounter()
      }.bind(this), 1000)
    },
    setTimeout () {
      var timeout = (this.counter * 1000)
      this.timer[0] = setTimeout(function () {
        this.setLogout()
      }.bind(this), timeout)
    },
    setLogout () {
      this.$store.commit('setLogout')
      this.$router.push({ name: 'signInTranslation', params: { lang: this.$i18n.locale } })
    }
  },
  mounted () {
    this.setTimeout()
    this.setCounter()
  },
  beforeDestroy () {
    clearTimeout(this.timer[0])
    clearTimeout(this.timer[1])
  }
}
</script>
